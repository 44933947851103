<template>
  <div class="iss-main-grid">
    <!--     分页 -->
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
          ref="gridRef"
          allow-selection
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #province="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #avatar="{ record }">
          <div  @click="handleName(record)" class="cursor-p" >
            <a-avatar v-if="record.avatar" :src="setAvatar(record.avatar)" :size="30" />
            <a-avatar :size="30" v-else>
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </div>
        </template>
        <template #userName="{ record }">
          <div @click="handleName(record)" class="cursor-p" v-if="record.userName">{{record.userName}}</div>
          <div @click="handleName(record)" class="cursor-p" v-else>暂无</div>
        </template>

        <template #content="{ record }">
          <!--        数量样式处理   -->
          <div class="cursor-p contentCla" @click="handleGotoView($event, record)">
<!--         这里可以点击查看详情    -->
            {{record.content}}
          </div>
        </template>


        <template #operation="{ record }">
          <operation :options="options" :record="record">
            <template #goDetail>
              <div class="primary-text cursor-p" @click="handleGotoView($event, record)">详情</div>
            </template>
            <template #more>
              <div class="primary-text cursor-p">
                <a-dropdown>
                  <a class="ant-dropdown-link" @click.prevent> 更多操作 </a>
                  <template #overlay>
                    <a-menu @click="clickOptions($event, record)">
                      <a-menu-item
                          v-for="item in itemOption"
                          :key="item.value"
                      >
                        <span class="padding-10">{{ item.label }}</span>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </template>
          </operation>
        </template>

      </grid>
    </div>
  </div>
  <is-view
      v-model:visible="visible"
      :init="activeItem"
      @fnOk="handleView"
  />
</template>

<script>
import { reactive, ref, toRefs, } from 'vue';
import { Modal, Menu, Dropdown, Avatar, message } from 'ant-design-vue';
import {
  UserOutlined,

} from '@ant-design/icons-vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import {useRouter} from "vue-router";
import isView from "@/views/feedBack/components/isView";
import {downloadExcel, setAvatar} from "@/utils";
import SearchForm from '@/components/searchForm';
import ordersApi from "@/api/orders.js";

export default {
  components: {
    // ARangePicker: DatePicker.RangePicker,
    // ASpace: Space,
    Operation,
    Grid,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ADropdown: Dropdown,
    SearchForm,
    UserOutlined,
    AAvatar: Avatar,
    isView,

  },
  setup() {
    const gridRef = ref();
    const router = useRouter();
    const state = reactive({
      search: {},
      TagList: [],
      visible: false,
      activeItem: {},

    });

    const clickOptions = ({ key }, record) => {
      console.log('更多操作', key, record);
      switch (key) {
        case 'order':
          router.push(`/user/${record.userId}?activeKey=3`)
          break;
        case 'invitation':
          router.push(`/user/${record.userId}?activeKey=2`)
          break;
        case 'disable':
          Modal.confirm({
            title: '确定要封禁该账号吗?',
            onOk: () => {
              console.log('OK');
            },
          });
          break;
      }
    };



    return {
      setAvatar,
      gridRef,
      ...toRefs(state),
      itemOption: [
        { value: 'order', label: 'ta的订单' },
        { value: 'invitation', label: 'ta的邀请' },
        { value: 'disable', label: '封禁账号' },
      ],
      items: [
        //  需要补上日期时间筛选
        {
          key: 'date',
          timeKey: ['startCreateTime', 'endCreateTime'],
          label: '日期范围',
          type: 'rangePicker',
        },
        {
          key: 'content',
          label: '搜索问题内容关键字',
          type: 'input',
        },

      ],
      columns: [
        { dataIndex: 'avatar', title: '头像', width: 60, ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        { dataIndex: 'userName', title: '昵称', width: 120, ellipsis: true,
          slots: { customRender: 'userName' },
        },
        { dataIndex: 'mobile', title: '手机号', width: 150, ellipsis: true },
        { dataIndex: 'content', title: '描述内容', width: 200, ellipsis: true,
          slots: { customRender: 'content' },
        },
        { dataIndex: 'createTime', title: '提交时间', width: 200, ellipsis: true,
          slots: { customRender: 'createTime' },
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 80,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'export',
          label: '导出全部数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: () => {
            ordersApi
                .excelExportDataFeedBack('contact:export', {})
                .then(({ name, data }) => downloadExcel(name, data));
          },
        },

        {
          type: 'export',
          label: '导出批量数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: (ids) => {
            if(ids.length) {
              ordersApi
                  .excelExportDataFeedBack('contact:export', { idList:[...ids]})
                  .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请至少选中一条数据！');
            }
          },
        },
      ],
      options: [
        {
          type: 'goDetail',
          slotName: 'goDetail',
          title: '详情',
          permission: 'user:goDetail',
          fnClick: () => {
            // router.push({
            //   name: '',
            //   query: {
            //     id,
            //   },
            // });

          },
        },

      ],
      url: ordersApi.feedbackUrl,
      gridHeight: document.body.clientHeight - 260,
      clickOptions,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleName: (record) => {
        return router.push(`/user/${record.userId}`)
      },
      handleView: (value) => {
        // 调取下载接口
        // if (ids.length) {
        ordersApi
            .excelExportData('contact:export', [value.id])
            .then(({ name, data }) => downloadExcel(name, data));
        // } else {
        //   message.info('请至少选中一条数据！');
        // }
        // state.visible = false
      },

      handleGotoView: ($event, record)=> {
        ordersApi.feedbackDetail('', record.id).then(res => {
          if (res) {
            state.activeItem = res
            state.visible = true
          }
        });


      },

    }

  },

}
</script>

<style scoped lang="less">
.iss-main-grid {
  padding: 0px 10px;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}

.contentCla {
  width: 200px;
  margin-left: 8px;
  white-space: nowrap; /*超出的空白区域不换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出显示省略号*/
}


</style>