<template>
  <div  class="modalClass" ref="container">
    <a-modal
        :visible="visible"
        title="反馈详情"
        :mask-closable="false"
        :width="600"
        centered
        @cancel="handleCancel"
        forceRender
        :footer="false"
        :destroyOnClose="true"
        :getContainer="() => container"
    >
      <div>

        <div class="userName">
          <div class="cursor-p avatar" >
            <a-avatar v-if="form.avatar" :src="setAvatar(form.avatar)" :size="32" />
            <a-avatar :size="32" v-else>
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </div>
          <div class="userName">{{form.userName}}</div>
          <div class="createTime">{{form.createTime}}</div>
        </div>

        <div class="content">
          <div>反馈内容：</div>
          <div class="mt-10">{{form.content}}</div>
        </div>

        <!--  图标展示  -->
        <div class="imgList">
          <file-upload-list
              :fileList="form.imgUrl"
          />

        </div>


      </div>

    </a-modal>
  </div>


</template>
<script>
import {reactive, toRefs, watch, ref} from 'vue';
import {Avatar,  } from 'ant-design-vue';

import {setAvatar, } from "@/utils";
import {
  UserOutlined,
} from '@ant-design/icons-vue';
import fileUploadList from "@/views/feedBack/components/fileUploadList.vue";
// import { useStore } from 'vuex';


export default {
  components: {
    UserOutlined,
    AAvatar: Avatar,
    fileUploadList,

  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    // const store = useStore();
    const container = ref();
    const form = reactive({
      avatar: null,
      content: '',
      imgUrl: [],
      userName: '',
      createTime: '',

    });

    const state = reactive({



    });

    const handleCancel = () => {
      context.emit('update:visible', false);
    };
    watch(
        () => props.init,
        value => {
          Object.assign(form, value)
          form.imgUrl = JSON.parse(form.imgUrl)
        }
    );


    return {
      ...toRefs(state),
      container,
      form,
      handleCancel,
      setAvatar,
      handleOpen() {
        state.openTiesGroup = true;
        // context.emit('fnOk', false);
      },


    };
  },
};
</script>
<style lang="less" scoped>
.modalClass /deep/ .ant-modal-body {
  height: 540px;
  overflow: auto;
  padding: 0px 24px;
  margin-top: 24px;
  font-size: 16px;

}
.userName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
.avatar {
  margin-right: 10px;

}
.userName {
  margin-right: 20px;
  max-width: 200px;

}
}
.content {
  margin-top: 20px;
}

.imgList {

}



</style>
